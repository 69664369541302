.tooltipBg{
  background-color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  border: 1px solid #CCC;
}

.tooltipText{
  font-size: 14px;
  color: #3C4654;
  margin: 0 0;
}

.recharts-cartesian-axis-tick-value{
  font-size: 12px;
}