@import '~app/mixins';

.chatOverlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  justify-content: center;
  align-items: center;

  &.visible {
    display: flex;
  }

  .chatContainer {
    width: 500px;
    height: 800px;
    background-color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 100px;
      height: 100px;

      use {
        fill: #5394f5;
      }
    }

    .closeBtn {
      position: absolute;
      right: -42px;
      top: -42px;
      width: 50px;
      height: 50px;
      background-color: white;
      border: 2px solid $color-grey-light;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 25px;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

:global {
  #hubspot-conversations-inline-iframe {
    width: 500px;
    height: 800px;
    border: none;
  }
}
