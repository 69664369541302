@import '~app/variables';

.header {
  font-size: 16px;
  line-height: 18px;
  padding-top: 10px;
  color: $color-blue-dark;
}

.text {
  font-size: 13px;
  margin: 10px 0;
  color: $color-blue-dark;
}

.body {
  margin: 0 15px;
  height: 100%;
}

.content {
  overflow-y: auto;
  height: calc(100% - 40px);
}

.buttonContainer {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
  position: relative;
}

.checkbox {
  position: absolute;
  right: 0;
  top: 20px;
}

.hiddenCell {
  visibility: hidden;
}
